@headerHeight: 50px;
@titleSize: 32px;
@titleTop: 50px;
@moduleTitleSize: 20px;
@unitHeight: 42px;

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #0784dc;
  height: @headerHeight;
  color: #ffffff;
  z-index: 99;
}

.header-left {
  float: left;
  font-size: 18px;
  height: @headerHeight;
  line-height: @headerHeight;
}

.header-right {
  font-size: 14px;
  float: right;
  height: @headerHeight;
  line-height: @headerHeight;
  .iconfont {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    margin-top: -2px;
    line-height: 1;
  }
}

.content {
  position: relative;
  margin-top: @headerHeight;
  background: url("../images/back.png") center bottom no-repeat;
  background-size: cover;
  padding-bottom: 100px;
  min-height: calc(100% - @headerHeight);
}

.content-title {
  position: relative;
  z-index: 99;
  text-align: center;
  font-size: @titleSize;
  padding-top: @titleTop;
  pointer-events: none;
}

.module {
  padding-top: 20px;
  padding-bottom: 18px;
  padding-left: 180px;
}

.module-title {
  font-size: @moduleTitleSize;
}

.module-desc {
  margin-top: @unitHeight / 2;
  padding-left: @unitHeight / 2;
}

.unit-box {
  position: relative;
  display: inline-block;
  margin-left: @unitHeight * 1.6;
  overflow: hidden;
  cursor: pointer;
  &:first-child {
    margin-left: 0;
  }
  .text-box {
    overflow: hidden;
    position: relative;
    left: -@unitHeight * 1.1;
    float: left;
    top: 12px;
  }
  .unit-text {
    border: 2px solid #d3d4d5;
    border-radius: @unitHeight / 2;
    background-color: #ffffff;
    height: @unitHeight;
    line-height: @unitHeight - 2;
    text-align: center;
    font-size: @unitHeight / 2.5;
    padding: 0 @unitHeight / 1.3 0 @unitHeight *  1.2;
    position: relative;
    left: -100%;
    transition: border-color .4s;
    margin: 5px;
    &:after {
      position: absolute;
      right: 0;
      top: -5px;
      font-size: 20px;
      font-family: 'iconfont', serif !important;
      content: '\e611';
      line-height: 1;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background-color: #ffffff;
      opacity: 0;
      transition: opacity .2s;
    }
  }
  .unit-icon {
    border: 2px solid #b9b9b9;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0 0 6px 2px rgba(95, 129, 162, 0.12);
    position: relative;
    left: 0;
    top: 0;
    margin: 10px 10px 10px 5px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    box-sizing: content-box;
    text-align: center;
    z-index: 9;
    float: left;
    transition: border-color .4s;

    .iconfont {
      font-size: @unitHeight / 1.8;
    }
    &:after {
      position: absolute;
      content: ' ';
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border: 3px #e6e6e6 solid;
      border-radius: 50%;
    }
  }
  &.unit-hover {
    .unit-text {
      border: 2px solid #61b6dc;
      color: #0784dc;
    }
    .unit-icon {
      border: 2px solid #61b6dc;
      color: #0784dc;
      transition: all .2s;
    }
  }
  &.unit-on {
    .unit-text {
      border: 2px solid #0784dc;
      color: #0784dc;
      &:after {
        opacity: 1;
      }
    }
    .unit-icon {
      border: 2px solid #0784dc;
      color: #0784dc;
    }
  }
}
.tree-box{
  position: relative;
  height: 500px;
}
.btn-box {
  position: fixed;
  bottom: 118px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  text-align: center;
  width: 153px;
  margin: auto;
}

.btn {
  text-align: center;
  border: 1px solid rgb(3, 113, 190);
  border-radius: 3px;
  background-color: rgb(7, 132, 220);
  height: @unitHeight;
  line-height: @unitHeight - 3;
  font-size: @unitHeight / 2;
  padding: 0 @unitHeight;
  display: inline-block;
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.tabs {
  .tabs-two {
    display: none;
  }
}

.footer {
  position: absolute;
  width: 100%;
  bottom: 15px;
  left: 0;
  text-align: center;
  color: #0784dc;
  font-size: 14px;
}

.tree-scroll {
  width: 1800px;
  height: 1300px;
  position: relative;
  left: 3%;
  cursor: move;
}

.tree-center {
  border: 2px solid rgba(255, 255, 255, .3);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, .2);
  box-shadow: 0 19px 36px 2px rgba(82, 81, 81, .08);
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.tree-center-one {
  border: 2px solid rgba(255, 255, 255, .3);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, .3);
  box-shadow: 0 0 18px 2px rgba(82, 81, 81, .08);
  margin: 15px;
}

.tree-center-two {
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, .5);
  margin: 15px;
}

.tree-center-three {
  border: 6px solid rgb(72, 156, 192);
  border-radius: 50%;
  background-color: #ffffff;
  width: 224px;
  height: 224px;
  margin: 15px;
  text-align: center;
  line-height: 224px;
  .iconfont {
    font-size: 115px;
    color: #1294d8;
  }
}

.work-box {
  position: absolute;
  left: 1035px;
  top: 605px;
}

.round-box {
  border-radius: 50%;
  border: 4px solid rgb(255, 255, 255);
  box-shadow: 0 13px 20.16px 0.84px rgba(0, 110, 192, 0.27);
  width: 85px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  position: relative;
  z-index: 1;
  box-sizing: content-box;
}

.work {
  background-color: #1474d7;
  background-image: linear-gradient(0deg, rgb(18, 110, 212) 0%, rgb(59, 167, 233) 100%);
}

.work-tree {
  position: absolute;
  left: 85px;
  top: 50%;
  margin-top: -4px;
  width: 458px;
  > .check{
    &:before{
      position: absolute;
      content: ' ';
      left: -62px;
      top: 50%;
      height: 8px;
      width: 65px;
      transform: translateY(-50%);
      background-color: #1474d7;
    }
  }
}

.check {
  position: relative;
  float: left;
  margin-left: 60px;
  width: 30px;
  height: 30px;
  margin-top: -12px;
  transition: all .2s;
  input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    opacity: 0;
    cursor: pointer;
    &:checked + i {
      border-color: #57befe;
      background-color: #116dd4;
      &:before {
        content: "\e615";
      }
    }
  }
  i {
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 30px;
    line-height: 26px;
    text-align: center;
    border: 3px #acacac solid;
    border-radius: 50%;
    color: #ffffff;
    background-color: #ffffff;
    transition: all .2s;
    z-index: 9;
    &:before {
      content: '';
    }
  }
}

.check-label {
  position: absolute;
  left: 50%;
  top: 38px;
  font-size: 20px;
  color: #333333;
  height: 80px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  writing-mode: vertical-lr;
  line-height: 1.2;
}

.work-3, .work-5 {
  -webkit-transform: translate(-50%, -160%);
  transform: translate(-50%, -160%);
}

.work-1 {
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
  margin-left: 5px;
}

.nodes-box {
  position: absolute;
  width: 4px;
  background-color: #116dd4;
  height: 98px;
  .check {
    width: 28px;
    height: 28px;
    i {
      width: 28px;
      height: 28px;
      line-height: 26px;
      border-width: 2px;
    }
  }
  &.on{
    background-color: #116dd4;
  }
}

.work-nodes {
  left: 50%;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}

.nodes-top {
  white-space: nowrap;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  width: 185px;
  height: 60px;
  &:after {
    position: absolute;
    bottom: 0;
    left: 15px;
    width: calc(100% - 30px);
    height: 4px;
    background-color: #116dd4;
    content: '';
  }
  &.on{
    &:after {
      background-color: #116dd4;
    }
    > .check {
      &:after {
        background-color: #116dd4;
      }
    }
  }
  > .check {
    display: inline-block;
    float: none;
    margin-left: 20px;
    .check-label {
      white-space: normal;
      text-align: right;
    }
    &:first-child {
      margin-left: 0;
    }
    &:after {
      position: absolute;
      top: 26px;
      left: 13px;
      width: 4px;
      height: 46px;
      background-color: #116dd4;
      content: '';
    }
  }
}

.check-nodes-three {
  > .check-label {
    margin-left: -20px;
  }
  .nodes-three {
    position: absolute;
    left: 13px;
    bottom: 28px;
    width: 360px;
    height: 170px;
    white-space: nowrap;
    .check {
      width: 24px;
      height: 24px;
      margin-left: 30px;
      display: inline-block;
      float: none;
      -webkit-transform: translateY(-4px);
      transform: translateY(-4px);
      i {
        width: 24px;
        height: 24px;
        font-size: 12px;
        line-height: 22px;
        border-width: 2px;
      }
      &:first-child {
        margin-left: 175px;
      }
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background-color: #116dd4;
      content: '';
    }
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: #116dd4;
      content: '';
    }
    &.on{
      &:after, &:before{
        background-color: #116dd4;
      }
    }
  }
}

.business-box {
  position: absolute;
  left: 857px;
  top: 786px;
}

.business {
  border: 4px solid rgb(255, 255, 255);
  border-radius: 50%;
  background-color: #ed7d31;
  background-image: linear-gradient(0deg, rgba(3, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
  box-shadow: 0 13px 20.16px 0.84px rgba(0, 110, 192, 0.27);
}

.business-tree {
  width: 505px;
  height: 130px;
  position: absolute;
  left: 50%;
  top: 200px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: #ed7c30;
    content: '';
  }
  &:before {
    position: absolute;
    top: -100%;
    left: 50%;
    margin-left: -4px;
    width: 8px;
    height: 100%;
    background-color: #ed7c30;
    content: '';
  }
  &.on{
    &:after, &:before{
      background-color: #ed7d31;
    }
  }
  .check {
    z-index: 9;
    margin-left: 65px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.check-business-nodes {
  > .check-label {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    left: 25px;
  }
  .nodes-box.on{
    background-color: #ed7d31;
  }
}

.business-nodes {
  position: absolute;
  width: 4px;
  background-color: #ed7c30;
  height: 98px;
  top: 28px;
  left: 13px;
  .nodes-top {
    position: absolute;
    top: 100%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    width: 214px;
    &:after {
      position: absolute;
      top: 0;
      left: 11px;
      width: calc(100% - 17px);
      height: 4px;
      background-color: #ed7c30;
      content: '';
    }
    &.on{
      &:after {
        background-color: #ed7d31
      }
      .check {
        &:after {
          background-color: #ed7d31
        }
      }
    }
    .check {
      top: 100%;
      .check-label {
        text-align: left;
      }
      &:after {
        position: absolute;
        top: -45px;
        left: 11px;
        width: 4px;
        height: 46px;
        background-color: #ed7c30;
        content: '';
      }
    }
  }
}

.live-box {
  position: absolute;
  left: 669px;
  top: 605px;
  .live {
    background-color: #00c270;
    background-image: linear-gradient(0deg, #009132 0%, #00c270 100%);
  }
  .live-tree {
    position: absolute;
    right: 80px;
    top: 50%;
    margin-top: -4px;
    width: 500px;
    padding:15px 0;
    .check {
      margin-left: 50px;
      &:first-child {
        margin-left: 0;
      }
    }
    &.on{
      background: #009944;
    }
    > .check{
      float: right;
      margin-left: 0;
      margin-right: 50px;
      &:before{
        position: absolute;
        content: ' ';
        right: -51px;
        top: 50%;
        height: 8px;
        width: 52px;
        transform: translateY(-50%);
        background-color: #009944;
      }
    }
  }
}

.check-tree {
  > .check-label {
    bottom: 40px;
    top: auto;
    left: 20px;
    -webkit-transform: none;
    transform: none;
  }
  > .iconfont{
    background-color: #0f8137;
    border-color: #00af50;
  }
  .live-nodes {
    height: 160px;
    width: 120px;
    position: absolute;
    left: 50%;
    top: 26px;
    .check {
      margin-left: 60px;
      width: 28px;
      height: 28px;
      i {
        width: 28px;
        height: 28px;
        line-height: 26px;
        border-width: 2px;
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .live-top {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: 30px;
    top: auto;
    &.on{
      &:after, &:before {
        background: #009944;
      }
      .check{
        &:after {
          background: #009944;
        }
      }
    }
    .check {
      margin-left: 64px;
      &:first-child {
        margin-left: 0;
      }
      &:after {
        position: absolute;
        left: 50%;
        top: 26px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        content: '';
        width: 4px;
        background-color: #009944;
        height: 48px;
      }
    }
    .check-label {
      bottom: 35px;
      top: auto;
      text-align: right;
    }
    &:after {
      position: absolute;
      left: 50%;
      bottom: 0;
      content: '';
      width: 4px;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      background-color: #009944;
      height: 98px;
    }
    &:before {
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      bottom: 98px;
      content: '';
      height: 4px;
      background-color: #009944;
      width: 90px;
    }
  }

}

.work-tree{
  .check-tree{
    > .iconfont{
      border-color: #57befe;
      background-color: #116dd4;
    }
  }
}
.check-business-nodes{
  > .iconfont{
    background-color: #ed7d31;
    border-color: #ed9b00;
  }
}

.check-tree-bottom {
  > .check-label {
    top: 40px;
    bottom: auto;
    left: 20px;
    -webkit-transform: none;
    transform: none;
  }
  > .iconfont{
    background-color: #0f8137;
    border-color: #00af50;
  }
  .live-bottom {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: auto;
    height: 160px;
    width: 142px;
    position: absolute;
    left: 50%;
    top: 26px;
    .check {
      margin-left: 64px;
      margin-top: 142px;
      &:first-child {
        margin-left: 9px;
      }
      &:after {
        position: absolute;
        left: 50%;
        bottom: 26px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        content: '';
        width: 4px;
        background-color: #009944;
        height: 48px;
      }
    }
    &.on{
      &:after, &:before {
        background-color: #009944
      }
      .check {
        &:after {
          background-color: #009944
        }
      }
    }
    .check-label {
      top: 35px;
      text-align: left;
    }
    &:after {
      position: absolute;
      left: 50%;
      top: 2px;
      content: '';
      width: 4px;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      background-color: #009944;
      height: 98px;
    }
    &:before {
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      top: 98px;
      content: '';
      height: 4px;
      background-color: #009944;
      width: 90px;
    }
  }
}

.tabs-three {
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 970px;
  transform: translate(-50%,-50%);
  z-index: 99;
  height: 100%;
}

.popups {
  display: none;
  position: fixed;
  left: 100px;
  top: 100px;
  width: 466px;
  padding-bottom: 20px;
  z-index: 99;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.24);
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  .popups-title {
    font-size: 18px;
    color: rgb(71, 71, 71);
    line-height: 42px;
    text-align: left;
    background-color: #eff4f9;
    padding: 0 15px;
    border-bottom: 1px #93c2e8 solid;
  }
  .popups-hint {
    font-size: 14px;
    color: #f36c10;
    line-height: 1.25;
    margin: 0 20px;
  }
  .popups-btn {
    display: block;
    border-radius: 3px;
    background-color: rgb(7, 132, 220);
    width: 111px;
    height: 32px;
    line-height: 32px;
    color: #ffffff;
    text-align: center;
    margin: 20px auto auto;
    cursor: pointer;
  }
  table {
    border-collapse: collapse;
    width: calc(100% - 20px);
   // border-bottom: 1px #dedede solid;
    margin: 20px;
    td {
     // border-top: 1px #dedede solid;
      padding: 5px;
      vertical-align: middle;
      &:first-child {
        //  border-right: 1px #dedede solid;
      }
      i {
        vertical-align: middle;
        display: inline-block;
        width: 18px;
        height: 18px;
        line-height: 18px;
        background-color: #7f7f7f;
        color: #ffffff;
        text-align: center;
        margin-right: 5px;
        font-size: 14px;
      }
      .box{
        padding:1px 0;
      }
    }
    span {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      padding: 0 5px;
      height: 18px;
      line-height: 18px;
      border-radius: 4px;
      margin-right: 5px;
      font-size: 12px;
    }
    span.am {
      background-color: rgb(89, 143, 193);
      color: #ffffff;

    }
    span.pm {
      background-color: #7793ae;
      color: #ffffff;
    }
    tr:first-child {
      td {
      //  border-top: 1px #000000 solid;
      }
    }
  }
}
.icon-bendishenghuo{
  margin-left: 3px;
  margin-top: -1.5px;
}
.accurate-box{
  transform: scale(.9);
  transform-origin: top center;
}

.accurate-content {
  position: relative;
  background-color: rgba(255, 255, 255, 0.98);
  border: #d0e2eb solid 1px;
  border-top: #068fb1 solid 2px;
  padding: 3px 25px 0;
  height: 85%;
  overflow: hidden;
  transform-origin: top center;
  margin-top: 15px;
}

.accurate-intro {
  background-color: #fff;
}

.intro-title {
  text-align: center;
  font-size: 24px;
  color: #474747;
  padding: 25px 15px;
}

.intro-title span {
  color: #0784dc;
}
.title-intar{
  line-height: 42px;
  margin-left: -100px;
  vertical-align: middle;
}
.accurate-scroll{
  position: relative;
  overflow: hidden;
}
.intro-content {
  padding: 40px 60px;
  background: url(../images/line-bg.png) repeat-x;
  border-top: #7793ae solid 1px;
}

.s-title {
  font-size: 20px;
  position: relative;
  padding-left: 20px;
}

.s-title:before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 5px;
  height: 16px;
  border-radius: 2px;
  background-color: #1869d8;
}

.intro-write {
  border-top: #3d3d3d solid 2px;
  margin-top: 10px;
}

.accurate-table {
  width: 100%;
}

.accurate-table td {
  border-bottom: #dedede solid 1px;
  padding: 15px 50px;
  font-size: 20px;
}

.accurate-table td:first-child {
  border-right: #dedede solid 1px;
  font-size: 18px;
  color: #727272;
  width: 210px;
}

.table-name .iconfont {
  display: inline-block;
  background-color: #7f7f7f;
  color: #fff;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  margin-right: 10px;
}

.time-list span {
  width: 62px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  background-color: #598fc1;
  color: #fff;
  display: inline-block;
  border-radius: 3px;
  font-size: 12px;
  margin-right: 18px;
}

.time-list.pm span {
  background-color: #7793ae;
}

.accurate-link {
  font-size: 0;
}

.accurate-link a {
  display: inline-block;
  font-size: 20px;
  color: #fff;
  background-color: #0784dc;
  border-radius: 4px;
  width: 212px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  margin-left: 20px;
  cursor: pointer;
}

.accurate-link a:first-child {
  margin-left: 0;
}

.accurate-link a:hover {
  background-color: #07b8dc;
}

.accurate-link a.active {
  background-color: #999;
}

.accurate-type {
  margin-top: 45px;
  overflow: hidden;
  padding: 0 20px;
  margin-bottom: 40px;
}

.accurate-share {
  float: left;
  width: 300px;
}

.share-content {
  text-align: center;
  margin-top: 15px;
}

.share-content img {
  width: 212px;
  height: 212px;
  border: #e0ecf2 solid 12px;
}

.code-tips {
  font-size: 18px;
  margin-top: 5px;
}

.matter {
  float: right;
  width: 480px;
}

.matter-list {
  border-top: #d0e2eb solid 1px;
  margin-top: 15px;
}

.matter-list ul li {
  padding: 15px 0;
  border-bottom: #737374 dashed 1px;
  overflow: hidden;
}

.matter-icon {
  float: left;
  width: 54px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  background-color: #1ab0b3;
  border-radius: 3px;
  margin-right: 20px;
}

.matter-icon .iconfont {
  font-size: 28px;
  color: #fff;
}

.matter-name {
  height: 48px;
  overflow: hidden;
  float: left;
  width: calc(100% - 75px);
}

.matter-name a {
  font-size: 20px;
  line-height: 24px;
  display: table;
  height: 100%;
}

.matter-name a:hover {
  color: #0784dc;
}

.matter-name a span {
  display: table-cell;
  vertical-align: middle;
}

.accurate-btn {
  text-align: center;
  margin: 50px 0;
}

.theme-btn {
  display: inline-block;
  width: 190px;
  height: 50px;
  text-align: center;
  line-height: 48px;
  border: #0371be solid 1px;
  border-radius: 2px;
  color: #fff;
  font-size: 24px;
  background-color: #0784dc;
  cursor: pointer;
}

.theme-btn:hover {
  background-color: #0371be;
}


.live-box{
  .check{
    input:checked + i{
      background-color: #0f8137;
      border-color: #00af50;
    }
  }
}
.business-tree{
  .check{
    input:checked + i{
      background-color: #ed7d31;
      border-color: #ed9b00;
    }
  }
}
